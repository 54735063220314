import './styles/global.scss';
import './styles/page.scss';
import imageBrandLogo from './images/brand-logo.svg';
import imageLinkLogoAurisStudio from './images/link-logo-aurisstudio.svg';
import imageLinkLogoUpwork from './images/link-logo-upwork.svg';
import imageLinkLogoGithub from './images/link-logo-github.svg';
import imageLinkLogoLinkedin from './images/link-logo-linkedin.svg';
import imageLinkLogoX from './images/link-logo-x.svg';
class App {
    root:Element = document.getElementById('app');
    loadContent() {
        this.root.innerHTML = `
<article id="page" class="has-animation do-animation">
        <section>
            <div class="page-title has-animation do-animation">
                <img class="brand-logo" src="${imageBrandLogo}" alt="Arshad Ansari's Logo">
                <h1>Arshad Ansari</h1>
                <p>Designing the Web, Defining the Future – Your Laravel and WordPress Specialist</p>

            </div>
            <div class="page-content has-animation do-animation">
                <nav class="link-stack">
                    <ul>
                        <li><a href="https://arshadansari.in/" target="_blank" aria-label="Personal Website">Personal Website</a></li>
                        <li><a href="https://aurisstudio.com/" target="_blank" aria-label="Auris Studio's website">
                               <span><img
                            src="${imageLinkLogoAurisStudio}" width="332" height="40" alt="Auris Studio's website"></span>
                                                              </a></li>
                        <li><a href="https://www.upwork.com/fl/~01c04784c4a06f04f6" target="_blank" aria-label="Upwork profile">
                                                              <span><img
                            src="${imageLinkLogoUpwork}" width="176" height="49"
                            alt="Upwork profile"></span>
                                                            </a></li>
                        <li><a href="https://github.com/ar5-in" target="_blank" aria-label="Github profile">
                                                            <span><img
                            src="${imageLinkLogoGithub}" width="159" height="43"
                            alt="Github profile"></span>
                                                       </a></li>
                        <li><a href="https://www.linkedin.com/in/arshadansari/" target="_blank" aria-label="LinkedIn profile">
                                                       <span><img
                            src="${imageLinkLogoLinkedin}" width="238" height="58"
                            alt="LinkedIn profile"></span>
                                                       </a></li>
                        <li><a href="https://twitter.com/ar5_in" target="_blank" aria-label="Twiter / X profile">
                                                       <span><img
                            src="${imageLinkLogoX}" width="39" height="40"
                            alt="Twiter / X profile"></span>
                                                         </a></li>
                    </ul>
                </nav>

                <button class="btn-contact" id="action-launch-email-client">Get In touch</button>
            </div>
        </section>
    </article>
`;
    }
    handleContactButtonClick() {
        const btn:HTMLElement = document.getElementById('action-launch-email-client');

        btn.onclick = (e) => {
            e.preventDefault();
            window.open(atob('bWFpbHRvOmhpQGFyNS5pbg=='));
        }
    }
}

const app = new App();
app.loadContent();
app.handleContactButtonClick();